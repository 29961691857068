import { template as template_bd5d3d02ab9b456f9026d1f03fd24897 } from "@ember/template-compiler";
const EmptyState = template_bd5d3d02ab9b456f9026d1f03fd24897(`
  <div class="empty-state-container">
    <div class="empty-state">
      <span data-test-title class="empty-state-title">{{@title}}</span>
      <div class="empty-state-body">
        <p data-test-body>{{@body}}</p>
      </div>
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default EmptyState;
