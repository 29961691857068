import { template as template_a3c1c252a96143d3b067345e101ba46e } from "@ember/template-compiler";
const WelcomeHeader = template_a3c1c252a96143d3b067345e101ba46e(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
