import { template as template_f300569faf0b4c4fb67cbf04d36c8f95 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_f300569faf0b4c4fb67cbf04d36c8f95(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
